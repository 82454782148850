<style lang="scss" scoped>
.progress-form {
  .img-uploader {
    .el-upload {
      display: block !important;
    }
  }
}
.upload-tip{
  color:#E6A23C;
  font-size: 12px;
}
</style>

<template>
  <!-- 用户新增/编辑 -->

  <section class="page-child-contianer">
    <el-form
      class="page-form progress-form"
      :model="formData"
      :rules="rules"
      ref="formData"
      label-width="84px"
    >
      <el-form-item label="工单名称:" prop="name">
        <el-input v-model="formData.name" placeholder="请输入工单名称" />
      </el-form-item>
      <el-form-item label="备注:" prop="desc">
        <el-input v-model="formData.desc" placeholder="请输入备注" />
      </el-form-item>
      <el-form-item label="图标:" prop="thumb">
        <el-upload
          class="img-uploader"
          action="/index/uploadAnnex"
          name="files[]"
          :headers="{
            token: uploadToken,
          }"
          accept=".jpg,.jpeg,.png,.gif"
          :show-file-list="false"
          :before-upload="uploadBefore"
          :on-error="uploadError"
          :on-success="uploadSuccess"
        >
          <img v-if="imageUrl" :src="imageUrl" class="img" />
          <i
            v-else
            style="font-size: 28px; color: #8c939d; margin-left: 35px"
            class="el-icon-plus avatar-uploader-icon"
          ></i>
          <el-input type="hidden" v-model="formData.thumb" prop="thumb" />
        </el-upload>
        <span class="upload-tip"> 建议尺寸300x300(px)</span>
      </el-form-item>
      <el-form-item label="排序:" prop="sort">
        <el-input-number v-model="formData.sort" :min="1" />
      </el-form-item>
      <el-form-item>
        <el-button @click="handleCancle">取 消</el-button>
        <el-button type="primary" @click="handleSubmit"> 确 定 </el-button>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
import ueditorcom from "@/components/ueditorcom";
import {
  workOrderProgressAdd,
  workOrderProgressEdit,
  workOrderProgressDetail,
} from "@api/workOrderProgress";
import { getWorkOrderList } from "@api/workOrder";
export default {
  name: "progressadd",
  inject: ["reload"],
  /**
   * 组件
   */
  components: {
    ueditorcom,
  },
  data() {
    return {
      id: null, //修改用户id
      imageUrl: "",
      uploadToken:'',
      formData: {
        sort: 200,
        checkList: [],
      }, //表单存储数据

      workOrderList: [], //工单（类型）列表

      rules: {
        name: [{ required: true, message: "请输入工单名称", trigger: "blur" }],
        thumb: [{ required: true, message: "请上传图标", trigger: "blur" }],
      }, //表单校验规则
    };
  },
  async created() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      this.getDetail();
    }
  },
  mounted() {
    this.uploadToken = sessionStorage.getItem("token");
  },
  methods: {
    /** 修改前获取详情信息 */
    async getDetail() {
      /**
       * 请求接口获取详情数据(部门详情)
       */
      let res = await workOrderProgressDetail(this.id);
      this.imageUrl = res.data.thumb_path_source;
      // 数据请求返回成功后打开弹窗
      this.formDialogVisible = true;
      this.$nextTick(() => {
        // 注意看这里
        this.formData = {
          ...this.formData,
          ...res.data,
        };
      });

      //请求返回成功后打开弹窗
      this.formDialogVisible = true;
    },
    /** 取消用户编辑 */
    handleCancle() {
      this.$refs["formData"].clearValidate();
      this.$router.go(-1);
    },
    /** 提交用户信息*/
    handleSubmit() {
      console.log("formData", this.formData);
      this.$refs["formData"].validate(async (valid) => {
        if (valid) {
          /**
           * 请求接口
           */
          // delete this.formData.delete_time;
          // delete this.formData.update_time;
          if (this.id) {
            // 存在id，执行编辑请求
            var res = await workOrderProgressEdit(this.formData);
          } else {
            // 不存在id，执行添加请求
            var res = await workOrderProgressAdd(this.formData);
          }
          if (res.code == 200) {
            this.$message({
              message: res.msg,
              type: "success",
            });
            // 请求后，不论是否成功，调用方法关闭弹窗

            this.handleCancle();
          }
        }
      });
    },
    /** 上传前处理 */
    uploadBefore(file) {
      const isImg =
        file.type == "image/jpeg" ||
        file.type == "image/jpg" ||
        file.type == "image/png" ||
        file.type == "image/gif";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isImg) {
        this.$message.error("上传图片只能是 .jpg,.jpeg,.png,.gif 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return isImg && isLt2M;
    },
    /** 上传成功 */
    uploadSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
      this.formData = {
        ...this.formData,
        thumb: res.data.list[0].id,
      };
    },
    /** 上传失败 */
    uploadError(err, file) {
      this.$message.warning("上传失败");
    },
  },
};
</script>
